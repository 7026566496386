var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',{ref:"header",staticClass:"header",attrs:{"id":"header"}},[_c('div',{staticClass:"auto-1440 clearfix"},[_c('div',{staticClass:"logo"},[_c('router-link',{attrs:{"to":"/"}},[_c('img',{attrs:{"src":_vm.data.Logo,"alt":""}})])],1),_c('nav',[_vm._l((_vm.navList),function(item){return [(item.IsShow)?[(item.Id === 35978)?_c('div',{key:item.Id,class:[
                            'item',
                            item.LinkUrl == _vm.navActiveId ? 'active' : '',
                        ]},[_c('a-dropdown',{scopedSlots:_vm._u([(_vm.subjectList.length > 0)?{key:"overlay",fn:function(){return [_c('a-menu',_vm._l((_vm.subjectList),function(sub){return _c('a-menu-item',[_c('a',{attrs:{"href":'#/learning/list?subject=' + sub.Id}},[_vm._v(_vm._s(sub.Title))])])}),1)]},proxy:true}:null],null,true)},[_c('em',[(item.IsTiku)?_c('a',{attrs:{"href":_vm.userInfo?.TokenUrl || item.LinkUrl,"target":"_blank"}},[_vm._v(" "+_vm._s(item.Title)+" ")]):_c('a',{attrs:{"href":item.LinkUrl}},[_vm._v(" "+_vm._s(item.Title)+" ")])])])],1):_c('div',{key:`${item.Id}-normal`,class:[
                            'item',
                            item.LinkUrl == _vm.navActiveId ? 'active' : '',
                        ]},[_c('em',[(item.IsTiku)?_c('a',{attrs:{"href":_vm.userInfo?.TokenUrl || item.LinkUrl,"target":"_blank"}},[_vm._v(" "+_vm._s(item.Title)+" ")]):_c('a',{attrs:{"href":item.LinkUrl}},[_vm._v(" "+_vm._s(item.Title)+" ")])])])]:_vm._e()]})],2),_c('div',{staticClass:"login"},[(!_vm.token)?_c('a-button',{staticClass:"btn",attrs:{"type":"primary"},on:{"click":_vm.login}},[_vm._v(" 登录 ")]):_vm._e(),(_vm.token)?_c('div',{staticClass:"avatar",on:{"click":function($event){return _vm.$router.push({ name: 'infomation' })}}},[_c('a-avatar',{attrs:{"size":"small","src":_vm.userInfo.ImageUrl}},[(!_vm.userInfo.ImageUrl)?_c('svg-icon',{staticClass:"icon_user",attrs:{"icon-class":"icon_user"}}):_vm._e()],1),_c('span',{staticClass:"username"},[_vm._v(" "+_vm._s(_vm.userInfo.Nickname || _vm.userInfo.LoginName || _vm.userInfo.TrueName)+" ")])],1):_vm._e(),(_vm.token)?_c('div',{staticClass:"avatar",on:{"click":_vm.logout}},[_c('span',{staticClass:"username"},[_vm._v("退出登录")])]):_vm._e()],1),_c('div',{staticClass:"search"},[_c('div',{staticClass:"input-box"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.keyword),expression:"keyword"}],attrs:{"type":"text","placeholder":"请输入搜索内容"},domProps:{"value":(_vm.keyword)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.goSearch.apply(null, arguments)},"input":function($event){if($event.target.composing)return;_vm.keyword=$event.target.value}}}),_c('svg-icon',{staticClass:"icon_search",attrs:{"icon-class":"icon_search"},nativeOn:{"click":function($event){return _vm.goSearch.apply(null, arguments)}}})],1)])]),_c('login-dialog')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }