<template>
    <div class="catelog">
        <div class="radiogrop">
            <!-- <a-radio-group name="radioGroup" :default-value="1">
                <a-radio value="1">知识关联</a-radio>
                <a-radio value="2">批注</a-radio>
            </a-radio-group> -->
        </div>
        <div class="right-tree">
            <a-tree
                class="model-tree"
                defaultExpandAll
                :tree-data="data"
                :show-icon="false"
                expandAction="click"
                :replace-fields="replaceFields"
                v-if="data.length > 0"
                @select="expand"
            >
                <template slot="title" slot-scope="{ levelTit, label, card }">
                    <template v-if="type === 'kdsj'">
                        <div class="tree_title" v-if="card.Type !== 3">
                            <span>{{ levelTit }}{{ label }}</span>
                            <svg-icon icon-class="icon_menu_down"></svg-icon>
                        </div>
                    </template>
                    <template v-else>
                        <div class="tree_title">
                            <span>{{ levelTit }}{{ label }}</span>
                            <svg-icon icon-class="icon_menu_down"></svg-icon>
                        </div>
                    </template>
                </template>
            </a-tree>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        data: {
            typeof: Array,
            default: () => {
                return [];
            },
        },
        type: String,
    },
    data() {
        return {
            replaceFields: {
                children: 'children',
            },
        };
    },
    methods: {
        expand(selectedKeys, e) {
            const { node } = e;
            if (e.nativeEvent.target.className) {
                node.onExpand();
            } else {
                if (selectedKeys.length > 0) {
                    this.scrollToEle(selectedKeys[0]);
                }
            }
        },
        // 跳转到模型内的相应部分
        scrollToEle(key) {
            const ele = $('#' + this.type + key);
            const parent = ele.parents('.model_main');
            parent.animate({ scrollTop: ele[0].offsetTop }, 500);
        },
    },
};
</script>
<style scoped lang="less">
:deep(.ant-tree-switcher) {
    display: none !important;
}
:deep(.ant-tree-child-tree) {
    padding: 0 !important;
    li {
        padding: 0 !important;
    }
}
.tree_title {
    font-size: 16px;
    color: rgb(51, 51, 51);
    line-height: 2;
    white-space: pre-wrap;
    display: block;
    margin-bottom: 10px;
}
:deep(.ant-tree) {
    & > li {
        padding: 0 !important;
        span {
            height: auto;
            padding: 0;
            border: 0 !important;
        }
    }
}
.radiogrop {
    margin-bottom: 20px;
    :deep(.ant-radio-wrapper) {
        margin-right: 50px;
        &:last-of-type {
            margin-right: 0;
        }
    }
    :deep(.ant-radio-checked) {
        .ant-radio-inner {
            border-color: #f6a75c;
            &::after {
                background-color: #f6a75c;
            }
        }
    }
}
.right-tree {
    // max-height: 660px;
    overflow-y: auto;
    padding-right: 20px;
    :deep(.ant-tree-node-content-wrapper) {
        width: 100% !important;
        display: block;
    }
    &::-webkit-scrollbar {
        width: 6px;
        border-radius: 3px;
        background-color: #e5e5e5;
    }
    &::-webkit-scrollbar-thumb {
        width: 100%;
        border-radius: 3px;
        background-color: #f6a75c;
    }
    .tree_title {
        font-size: 16px;
        color: rgb(0, 0, 0);
        line-height: 1.5;
        display: flex;
        justify-content: space-between;
        .svg-icon {
            margin-left: 20px;
            color: #888;
            transform-origin: 50%;
            transition: 0.3s;
        }
    }
    .ant-tree-node-content-wrapper-open {
        .svg-icon {
            transform: rotate(90deg);
        }
    }
    .ant-tree-node-content-wrapper-normal {
        .svg-icon {
            display: none;
        }
    }
}
:deep(.ant-tree) {
    & > li {
        margin-bottom: 25px;
        & > .ant-tree-node-content-wrapper {
            // margin-bottom: 10px;
            .tree_title {
                .svg-icon {
                    font-size: 11px;
                    margin-top: 6px;
                }
            }
        }
        & > ul {
            background-color: #fff;
            padding: 10px !important;
            .tree_title {
                font-size: 14px;
                line-height: 1.714;
                color: rgb(136, 136, 136);
                // margin-bottom: 10px;
                .svg-icon {
                    font-size: 8px;
                    margin-top: 8px;
                }
            }
            .ant-tree-node-content-wrapper {
                // margin-bottom: 10px;
            }
        }
    }
}
:deep(.ant-pagination-prev),
:deep(.ant-pagination-next),
:deep(.ant-pagination-item) {
    width: 30px;
    height: 30px !important;
    line-height: 30px !important;
}
:deep(.ant-pagination-prev) {
    margin-right: 10px;
}
:deep(.ant-pagination-next) {
    margin: 0;
}
</style>
