<template>
    <header class="header" ref="header" id="header">
        <div class="auto-1440 clearfix">
            <div class="logo">
                <router-link to="/">
                    <img :src="data.Logo" alt="" />
                </router-link>
            </div>
            <nav>
                <template v-for="item in navList">
                    <template v-if="item.IsShow">
                        <div 
                            v-if="item.Id === 35978" 
                            :class="[
                                'item',
                                item.LinkUrl == navActiveId ? 'active' : '',
                            ]" 
                            :key="item.Id"
                        >
                            <a-dropdown>
                                <em>
                                    <a v-if="item.IsTiku" :href="userInfo?.TokenUrl || item.LinkUrl" target="_blank">
                                        {{ item.Title }}
                                    </a>
                                    <a v-else :href="item.LinkUrl">
                                        {{ item.Title }}
                                    </a>
                                </em>
                                <template #overlay v-if="subjectList.length > 0">
                                    <a-menu>
                                        <a-menu-item v-for="sub in subjectList">
                                            <a :href="'#/learning/list?subject=' + sub.Id">{{ sub.Title }}</a>
                                        </a-menu-item>
                                        
                                    </a-menu>
                                </template>
                            </a-dropdown>   
                            
                        </div>
                        <div 
                            
                            :class="[
                                'item',
                                item.LinkUrl == navActiveId ? 'active' : '',
                            ]" 
                            v-else
                           :key="`${item.Id}-normal`"
                        >
                            <em>
                                <a v-if="item.IsTiku" :href="userInfo?.TokenUrl || item.LinkUrl" target="_blank">
                                    {{ item.Title }}
                                </a>
                                <a v-else :href="item.LinkUrl">
                                    {{ item.Title }}
                                </a>
                            </em>
                        </div>
                    </template>
                    
                </template>
                <!-- <template v-for="item in nav">
                    <template v-if="item.name == 'forum'">
                        <div
                            v-if="item.meta.isNav && data.IsOpenForum"
                            :class="[
                                'item',
                                item.meta.ActiveId == navActiveId
                                    ? 'active'
                                    : '',
                            ]"
                            :key="item.meta.ActiveId"
                        >
                            <em>
                                <a
                                    v-if="item.name == 'practice'"
                                    :href="userInfo?.TokenUrl || item.meta.link"
                                    target="_blank"
                                >
                                    {{ item.meta.title }}
                                </a>
                                <a v-else @click="switchRouter(item)">
                                    {{ item.meta.title }}
                                </a>
                            </em>
                        </div>
                    </template>
                    <div
                        v-if="item.meta.isNav && item.name !== 'forum'"
                        :class="[
                            'item',
                            item.meta.ActiveId == navActiveId ? 'active' : '',
                        ]"
                        :key="item.meta.ActiveId"
                    >
                        <em>
                            <a
                                v-if="item.name == 'practice'"
                                :href="userInfo?.TokenUrl || item.meta.link"
                                target="_blank"
                            >
                                {{ item.meta.title }}
                            </a>
                            <a v-else @click="switchRouter(item)">
                                {{ item.meta.title }}
                            </a>
                        </em>
                    </div>
                </template> -->
            </nav>
            <div class="login">
                <a-button type="primary" class="btn" v-if="!token" @click="login">
                    登录
                </a-button>
                <div class="avatar" v-if="token" @click="$router.push({ name: 'infomation' })">
                    <a-avatar size="small" :src="userInfo.ImageUrl">
                        <svg-icon class="icon_user" icon-class="icon_user" v-if="!userInfo.ImageUrl"></svg-icon>
                    </a-avatar>
                    <span class="username">
                        {{
                            userInfo.Nickname ||
                            userInfo.LoginName ||
                            userInfo.TrueName
                        }}
                    </span>
                    <!-- <div class="dropdown">
                        <div class="inner">
                            <div class="item">
                                <router-link :to="{ name: 'infomation' }" class="left">
                                    <svg-icon icon-class="tip"></svg-icon>
                                    <span>基本信息</span>
                                </router-link>
                            </div>
                            <div class="item">
                                <div class="left" @click="logout">
                                    <svg-icon icon-class="Logout"></svg-icon>
                                    <span>退出登录</span>
                                </div>
                            </div>
                        </div>
                    </div> -->
                </div>
                <div class="avatar" v-if="token" @click="logout">
                    <span class="username">退出登录</span>
                </div>
            </div>
            <div class="search">
                <div class="input-box">
                    <input type="text" placeholder="请输入搜索内容" v-model="keyword" @keyup.enter="goSearch" />
                    <svg-icon class="icon_search" icon-class="icon_search" @click.native="goSearch"></svg-icon>
                </div>
            </div>
        </div>
        <!-- 登录弹窗 -->
        <login-dialog></login-dialog>
        <!-- 退出确认框 -->
    </header>
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import routes from '@/router'
import LoginDialog from './loginDialog.vue'
export default {
    props: {
        data: {
            type: Object,
            default: () => {
                return {
                    Logo: '',
                }
            },
        },
    },
    components: {
        LoginDialog,
    },
    data() {
        return {
            nav: routes.options.routes,
            keyword: '',
            privacy: "",
            subjectList: []
        }
    },
    computed: {
        ...mapState({
            userInfo: state => state.user.userInfo,
            isLogin: state => state.user.isLogin,
            loginDialog: state => state.user.loginDialog,
            token: state => state.user.token,
            navActiveId: state => state.user.navActiveId,
            navList: state => state.app.navList,
        }),
    },
    methods: {
        // 打开登录弹窗
        login() {
            this.$store.commit('user/saveLoginDialog', true)
        },
        // 退出登录
        logout() {
            this.$store.dispatch('user/logout', true)
            if (this.$route.meta.requiresAuth || this.$router.path == '/') {
                this.$router.replace('/')
            }
        },
        // 页面跳转
        switchRouter(item) {
            if (item.meta.isDeveloping) {
                this.$message.info('开发中。。。')
            } else if (item.meta.link) {
                window.open(item.meta.link)
            } else {
                this.$router.push(item.path)
            }
        },
        // 搜索
        goSearch() {
            if (!this.keyword) return
            this.$router.push({ name: 'search', params: { key: this.keyword } })
        },
        // 获取考试科目
        async getSubjectList() {
            this.loading = true
            this.subjectList = await this.$store.dispatch('model/getSubject')
        },
    },
    created() {
        this.getSubjectList()
    }
}
</script>
<style scoped lang="less">
.header {
    background-color: #000f23;
    flex: 0;
    position: relative;
    z-index: 20;

    .logo {
        float: left;
        padding: 24.5px 0;

        img {
            width: 196px;
            height: 51px;
            object-fit: contain;
        }
    }

    nav {
        float: left;
        display: flex;
        margin-left: 95px;

        .item {
            padding: 0 25px;

            &.active {
                a {
                    color: #00ffc0;
                }

                em::before {
                    width: 100%;
                }
            }
        }

        em {
            display: block;
            line-height: 100px;
            position: relative;

            &::before {
                content: '';
                width: 0;
                height: 5px;
                background-color: #00ffc0;
                position: absolute;
                top: 0;
                left: 50%;
                transform: translate(-50%);
                transition: 0.3s;
            }
        }

        a {
            display: inline-block;
            vertical-align: top;
            font-size: 18px;
            color: #fff;
            font-weight: bold;

            &:hover {
                color: #00ffc0;
            }
        }
    }

    .search {
        float: right;
        padding: 30px 0;

        .input-box {
            background-color: #fff;
            border-radius: 3px;
            padding: 0 15px;
            height: 40px;
            display: flex;
            align-items: center;
            width: 300px;
            margin-right: 10px;

            input {
                font-size: 16px;
                line-height: 40px;
                flex: 1;

                &::placeholder {
                    color: rgb(187, 187, 187);
                }
            }

            .icon_search {
                font-size: 16px;
                cursor: pointer;
                flex-shrink: 0;
                color: #bbb;
            }
        }
    }

    .login {
        float: right;
        padding: 30px 0;
        min-width: 120px;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        cursor: pointer;

        .btn {
            height: 40px;
            width: 100%;
            text-align: center;
            color: rgb(0, 0, 0);
            border-radius: 3px;
            background-color: #00ffc0;
            border-color: #00ffc0;
            line-height: 40px;
            font-weight: bold;

            &:hover {
                background-color: #79f9d9;
                border-color: #79f9d9;
                box-shadow: 0 0 10px 0 rgb(121, 249, 217, 0.8);
            }
        }

        .avatar {
            font-size: 16px;
            color: #fff;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .ant-avatar {
                margin-left: 5px;
            }

            position: relative;

            &:hover {
                .dropdown {
                    transform: translateY(0);
                    opacity: 1;
                    visibility: visible;
                }
            }
        }

        .logout {
            width: auto;
            padding: 0 10px;
            margin-left: 5px;
        }

        .username {
            color: #fff;
            min-width: 63px;
            line-height: 1;
            font-weight: bold;
            font-size: 16px;
            padding-left: 10px;
        }

        :deep(.ant-avatar-sm) {
            width: 26px;
            height: 26px;
            background-color: transparent;
        }

        .icon_user {
            font-size: 26px;
            color: #fff;
        }

        :deep(.ant-avatar-string) {
            transform: scale(1) translateX(-50%) !important;
        }

        .dropdown {
            position: absolute;
            right: 0;
            top: 100%;
            padding-top: 15px;
            transform: translateY(-5px);
            opacity: 0;
            transition: 0.3s;
            visibility: hidden;

            .inner {
                padding: 20px 20px 10px;
                width: 240px;
                background-color: #fff;
                border-radius: 10px;
                position: relative;
                box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);

                &::before {
                    content: '';
                    width: 0;
                    height: 0;
                    border: 8px solid transparent;
                    border-bottom-color: #fff;
                    position: absolute;
                    right: 40px;
                    top: -13px;
                }

                .item {
                    line-height: 40px;
                    display: flex;
                    justify-content: space-between;
                    align-content: center;
                    border-bottom: 1px solid rgb(229, 229, 229);
                    transition: 0.3s;

                    &:hover {
                        background-color: rgba(0, 255, 192, 0.2);
                    }

                    .left {
                        color: rgb(0, 0, 0);
                        display: flex;
                        align-items: center;
                    }

                    .svg-icon {
                        font-size: 16px;
                        color: #888;
                        margin-right: 10px;
                    }
                }
            }

            :deep(.ant-badge-count) {
                background-color: #00ffc0;
                color: #000;
            }
        }
    }
}
</style>
